import * as Sentry from '@sentry/browser';
import { Integrations as ApmIntegrations } from '@sentry/apm';

const shouldTrack = !!__SENTRYIO_DSN_URL__;
if (shouldTrack) {
    Sentry.init({
        dsn: __SENTRYIO_DSN_URL__,
        beforeSend(event, hint) {
            // Check if it is an exception, and if so, show the report dialog
            // if (event.exception) {
            //     Sentry.showReportDialog({ eventId: event.event_id });
            // }
            return event;
        },
        integrations: [
            new ApmIntegrations.Tracing(),
        ],
        tracesSampleRate: 0.1,
    });
}
